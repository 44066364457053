import { type EnvironmentalVariables } from '~/types/environmentVariables';
import { type Location } from '@remix-run/router/dist/history';
import { type ServerRuntimeMetaArgs, type ServerRuntimeMetaDescriptor } from '@remix-run/server-runtime'; ///server-runtime/dist/routeModules';

type MetaTag = ServerRuntimeMetaDescriptor;

export const baseMetaTags = ({ env, location }: { env?: EnvironmentalVariables; location: Location<any> }) => {
  const title = '【自宅で簡単に理想の車を】| 車の通販バディカダイレクト - LINEで相談';
  const description =
    '【簡単・ワクワク・安心の車選び】オンラインで簡単に車を選び、自宅に届けます。BUDDICAの厳選された特別な車両と、透明で安心な手続きプロセスで、あなたの理想の1台を見つけましょう。LINEでの相談も可能。';

  const baseMeatas = [
    { title: title },
    { name: 'title', content: title },
    { name: 'description', content: description },
    { property: 'og:url', content: `${env?.baseUrl || ''}${location.pathname}${location.search || ''}` },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: description },
    { property: 'og:image', content: `${env?.baseUrl}/images/meta/ogp.png` },
    { property: 'og:site_name', content: 'バディカダイレクト' },
    { property: 'og:locale', content: 'ja_JP' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@yuusaku_buddica' }
  ];

  if (env?.environment !== 'production') {
    baseMeatas.push({ name: 'robots', content: 'noindex,nofollow' });
  }

  return baseMeatas;
};

export const mergeMetaTags = ({
  metaTags,
  matches
}: {
  metaTags: MetaTag[];
  matches: ServerRuntimeMetaArgs['matches'];
}) => {
  const parentMeta = matches.flatMap((match) => match.meta ?? []);
  const notDuplicateParentMeta = parentMeta.filter((meta) => {
    if ('title' in meta) {
      return !metaTags.some((tag) => 'title' in tag);
    }

    if ('name' in meta) {
      return !metaTags.some((tag) => 'name' in tag && tag.name === meta.name);
    }

    if ('property' in meta) {
      return !metaTags.some((tag) => 'property' in tag && tag.property === meta.property);
    }

    return true;
  });
  return [...notDuplicateParentMeta, ...metaTags];
};
